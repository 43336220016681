import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import errorHandle from "./error_handleing.js";
import { BASE_URL } from "./config"; // Import the BASE_URL variable

window.onerror = function (msg, url, lineNo, columnNo, error) {
  errorHandle(msg, lineNo, columnNo, error);
  alert(
    "msg: ",
    msg,
    "\nlineNo: ",
    lineNo,
    "\ncolumnNo: ",
    columnNo,
    "\nerror: ",
    error
  );
};

export default function Login() {
  const [user_email, setUserEmail] = useState("");
  const [user_password, setUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  axios.defaults.withCredentials = true;
  useEffect(() => {
    try {
      axios
        .get(`${BASE_URL}/api/login`)
        .then((response) => {
          if (response.data.loggedIn === true) {
            window.location = `/home`;
          }
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, []);
  const login = () => {
    try {
      axios
        .post("/login", {
          user_email: user_email,
          user_password: user_password,
        })
        .then(
          (response) => {
            if (response.data.match === "Incorrect Username or Password") {
              alert(response.data.match);
            } else {
              if (response.data.location !== null) {
                window.location = `/${response.data.location}`;
              } else {
                window.location = `/home`;
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  function handleSubmit(event) {
    event.preventDefault();
  }
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username"
            name="user_email"
            autoComplete="email"
            onChange={(e) => {
              setUserEmail(e.target.value);
            }}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="user_password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            onChange={(e) => {
              setUserPassword(e.target.value);
            }}
            autoComplete="current-password"
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={login}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  );
}
