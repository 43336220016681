import React from 'react';
import Nav from './components/nav.js';
import Login from './components/login_page.js';
import Home from './components/home_page.js';
import Customer from './components/customer_page.js';
import Signup from './components/signup_page.js';
import Status from './components/status_page.js';
import Dispatch from './components/dispatch_form.js';
import Board from './components/board_page.js';
import Shipper from './components/shipper_page.js';
import Location from './components/locations_page.js';
import Admin from './components/admin_page.js';
import Invoice from './components/invoicing_page.js';
import Billing from './components/billing_page.js';
import Payroll from './components/payroll_page.js';
import PortLocations from './components/port_locations_page.js'
import {BrowserRouter, Routes , Route} from 'react-router-dom';
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <header className="App-header">
        <link rel="stylesheet" href='https://cdn.syncfusion.com/ej2/material.css'/>
          <Nav/>
            <Routes >
              <Route path="/" element={<Login />}/>
              <Route path="/home" element={<Home />}/>
              <Route path="/customer" element={<Customer />}/>
              <Route path="/signup" element={<Signup />}/>
              <Route path="/status" element={<Status />}/>
              <Route path="/dispatch" element={<Dispatch />}/>
              <Route path="/dispatch/:number" element={Dispatch}/>
              <Route path="/board" element={<Board />}/>
              <Route path="/shippers" element={<Shipper />}/>
              <Route path="/Locations" element={<Location />}/>
              <Route path="/admin" element={<Admin />}/>
              <Route path="/invoice" element={<Invoice />}/>
              <Route path="/billing" element={<Billing />}/>
              <Route path="/payroll" element={<Payroll />}/>
              <Route path="/ports" element={<PortLocations />}/>
            </Routes >
        </header>
      </div>
    </BrowserRouter>
  );
}
export default App;