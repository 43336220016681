import React, { useState, useEffect, forwardRef } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
} from "@material-ui/core";
import { FormControlLabel, Checkbox } from "@mui/material";
import { createMuiTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import errorHandle from "./error_handleing.js";
import MaterialTable from "material-table";
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@mui/icons-material";
import { BASE_URL } from "./config"; // Import the BASE_URL variable

window.onerror = function (msg, url, lineNo, columnNo, error) {
  errorHandle(msg, lineNo, columnNo, error);
  alert(
    "msg: ",
    msg,
    "\nlineNo: ",
    lineNo,
    "\ncolumnNo: ",
    columnNo,
    "\nerror: ",
    error
  );
};
export default function Status() {
  // holds values for all necesary information
  const [isActive, setIsActive] = useState(true);
  const [perms, setPerms] = useState(false);
  const [readonly, setreadonly] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [driverID, setDriverID] = useState(null);
  let today = new Date();
  today =
    String(today.getMonth() + 1).padStart(2, "0") +
    "/" +
    String(today.getDate()).padStart(2, "0") +
    "/" +
    today.getFullYear();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  // axios config
  axios.defaults.withCredentials = true;
  // post request for creating drivers
  const driverInsert = () => {
    try {
      if (
        !(
          driver === "" ||
          street === "" ||
          city === "" ||
          zipcode === "" ||
          state === ""
        )
      ) {
        axios
          .post(`${BASE_URL}/drive/upsert`, {
            driver: driver.trim(),
            street: street.trim(),
            city: city.trim(),
            zipcode: zipcode.trim(),
            state: state.trim(),
            date: today,
            type: "insert",
          })
          .then((response) => {
            console.log(response.data.check);
            if (response.data.check === "Driver Added") {
              alert(response.data.check);
              let driverUpdateOBJ = {
                driver: driver,
                street: street,
                city: city,
                zip: zipcode,
                state: state,
              };
              let newDrivers = [...drivers];
              newDrivers.push(driverUpdateOBJ);
              setDrivers(newDrivers);
              setDriverID(null);
              setDriver("");
              setStreet("");
              setCity("");
              setZipcode("");
              setState("");
            } else {
              alert(response.data.check);
            }
          });
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  // post request for updating driver
  const driverUpdate = () => {
    try {
      if (
        !(
          driver === "" ||
          street === "" ||
          city === "" ||
          zipcode === "" ||
          state === ""
        )
      ) {
        // let isactive = 1
        // if(isActive === "true"){
        //   isactive = 1
        // } else {
        //   isactive = 0
        // }
        console.log(isActive);
        axios
          .post(`${BASE_URL}/drive/upsert`, {
            driver: driver,
            street: street,
            city: city,
            zipcode: zipcode,
            state: state,
            date: today,
            active: isActive,
            type: "update",
          })
          .then((response) => {
            if (response.data.check === "Driver Updated") {
              alert(response.data.check);
              let driverUpdateOBJ = {
                driver: driver,
                street: street,
                city: city,
                zip: zipcode,
                state: state,
              };
              let newDrivers = [...drivers];
              newDrivers[driverID] = driverUpdateOBJ;
              setDrivers(newDrivers);
              setDriverID(null);
              setDriver("");
              setStreet("");
              setCity("");
              setZipcode("");
              setState("");
              setreadonly(false);
            } else if (
              response.data.check ===
              "Driver Does Not Exist. Please create driver before updating"
            ) {
              alert(response.data.check);
            }
            console.dir(response.data.check);
          });
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const newDriver = () => {
    try {
      setDriverID(null);
      setDriver("");
      setStreet("");
      setCity("");
      setZipcode("");
      setState("");
      setreadonly(false);
      driverGet();
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const driverGet = () => {
    try {
      axios
        .post(`${BASE_URL}/drive/getall`)
        .then((response) => {
          let driverarray = [];

          for (let x = 0; x < response.data.searchData.length; x += 11) {
            let driverOBJ = {
              driver: response.data.searchData[x + 1].trim(),
              street: response.data.searchData[x + 2].trim(),
              city: response.data.searchData[x + 3].trim(),
              zip: response.data.searchData[x + 4].trim(),
              state: response.data.searchData[x + 5].trim(),
              active: response.data.searchData[x + 10].trim(),
            };
            console.dir(driverOBJ);
            driverarray.push(driverOBJ);
          }
          setDrivers(driverarray);
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const driverSet = (data, id) => {
    setDriverID(id);
    setDriver(data.driver);
    setStreet(data.street);
    setCity(data.city);
    setZipcode(data.zip);
    setState(data.state);
    if (data.active === "true") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };
  // get request to make sure user is infact logged in and load data from last login
  useEffect(() => {
    try {
      axios
        .get(`${BASE_URL}/api/login`)
        .then((response) => {
          if (response.data.loggedIn === true) {
            if (
              response.data.permissions.admin === "true" ||
              response.data.permissions.admin === "1"
            ) {
              setPerms(true);
            }
            if (
              response.data.permissions.dispatch === "true" ||
              response.data.permissions.dispatch === "1"
            ) {
              setPerms(true);
            }
            if (
              response.data.permissions.payroll === "true" ||
              response.data.permissions.payroll === "1"
            ) {
              setPerms(true);
            }
            if (
              response.data.permissions.billing === "true" ||
              response.data.permissions.billing === "1"
            ) {
              setPerms(true);
            }
          } else {
            window.location = "/";
          }
        });
      driverGet();
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, []);
  // this function stops the auto reload from react
  // must be called by the form on submit
  function handleSubmit(event) {
    event.preventDefault();
  }
  const theme = createMuiTheme({
    palette: {
      type: "light",
    },
  });
  function handleClick(selectedRow) {
    setreadonly(true);
    driverSet(drivers[selectedRow.tableData.id], selectedRow.tableData.id);
  }
  switch (perms) {
    case true:
      return (
        <ThemeProvider theme={theme}>
          {console.dir(drivers)}
          <CssBaseline />
          <Grid container spacing={3} style={{ marginTop: 30 }}>
            {/* <IdleTimer ref={idleTimerRef} timeout={timeout} onIdle={onIdle}></IdleTimer> */}
            <Grid item xs={6} sm={6}>
              <MaterialTable
                icons={tableIcons}
                title="Drivers"
                data={drivers}
                columns={[
                  { field: "driver", title: "name" },
                  { field: "street", title: "street" },
                  { field: "city", title: "city" },
                  { field: "state", title: "state" },
                ]}
                options={{
                  paging: false,
                  cellStyle: { borderRight: "1px solid " },
                  headerStyle: { borderRight: "1px solid " },
                  maxBodyHeight: 700,
                }}
                onRowClick={(evt, selectedRow) => handleClick(selectedRow)}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography component="h1" variant="h5">
                Drivers Page
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  value={driver || ""}
                  required
                  fullWidth
                  id="driver"
                  label="driver"
                  name="driver"
                  style={{ marginTop: 10 }}
                  inputProps={{ readOnly: readonly }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setDriver("");
                    } else {
                      setDriver(e.target.value.trim());
                    }
                  }}
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  value={street || ""}
                  required
                  fullWidth
                  id="street"
                  label="street"
                  name="street"
                  style={{ marginTop: 10 }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setStreet("");
                    } else {
                      setStreet(e.target.value);
                    }
                  }}
                />
                <TextField
                  variant="outlined"
                  value={city || ""}
                  required
                  fullWidth
                  id="city"
                  label="city"
                  name="city"
                  style={{ marginTop: 10 }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setCity("");
                    } else {
                      setCity(e.target.value);
                    }
                  }}
                />
                <TextField
                  variant="outlined"
                  value={zipcode || ""}
                  required
                  fullWidth
                  id="zipcode"
                  label="zipcode"
                  name="zipcode"
                  style={{ marginTop: 10 }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setZipcode("");
                    } else {
                      setZipcode(e.target.value);
                    }
                  }}
                />
                <TextField
                  variant="outlined"
                  value={state || ""}
                  required
                  fullWidth
                  id="state"
                  label="state"
                  name="state"
                  style={{ marginTop: 10 }}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setState("");
                    } else {
                      setState(e.target.value);
                    }
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isActive}
                      onChange={(e) => {
                        setIsActive(e.target.checked);
                      }}
                    />
                  }
                  label="Active Status"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 10 }}
                  onClick={driverUpdate}
                >
                  Update driver
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 10 }}
                  onClick={driverInsert}
                >
                  Create driver
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 10 }}
                  onClick={newDriver}
                >
                  New Driver
                </Button>
              </form>
            </Grid>
          </Grid>
        </ThemeProvider>
      );
    case false:
      return "you do not have the correct permissions";
    default:
      return "you do not have permissions";
  }
}
