import React, { useState, useEffect, useRef, forwardRef } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
} from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useIdleTimer } from "react-idle-timer";
import errorHandle from "./error_handleing.js";
import MaterialTable from "material-table";
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@mui/icons-material";
import { BASE_URL } from "./config"; // Import the BASE_URL variable

window.onerror = function (msg, url, lineNo, columnNo, error) {
  errorHandle(msg, lineNo, columnNo, error);
  alert(
    "msg: ",
    msg,
    "\nlineNo: ",
    lineNo,
    "\ncolumnNo: ",
    columnNo,
    "\nerror: ",
    error
  );
};
export default function PortLocations() {
  const [locationID, setLocationID] = useState(null);
  const [perms, setPerms] = useState(false);
  const [readonly, setreadonly] = useState(false);
  const [locations, setLocations] = useState([]);
  const [location, setLocation] = useState("");
  const [country, setCountry] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const idleTimerRef = useRef(null);
  let today = new Date();
  today =
    String(today.getMonth() + 1).padStart(2, "0") +
    "/" +
    String(today.getDate()).padStart(2, "0") +
    "/" +
    today.getFullYear();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  // axios config
  axios.defaults.withCredentials = true;
  // post request to logout user on idle
  const onIdle = () => {
    try {
      const location = window.location.href.split("/");
      let data = `${location}|${country}|${street}|${city}|${zipcode}|${state}`;
      axios
        .post(`${BASE_URL}/logout`, {
          location: location[3],
          cache: data,
        })
        .then((response) => {
          window.location = "/";
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: 60 * 15 * 1000,
    onIdle: onIdle,
  });
  // post request for creating locations
  const locationInsert = () => {
    try {
      if (
        !(
          location === "" ||
          country === "" ||
          street === "" ||
          city === "" ||
          zipcode === "" ||
          state === ""
        )
      ) {
        axios
          .post(`${BASE_URL}/portLocations/upsert`, {
            location: location,
            country: country,
            street: street,
            city: city,
            zipcode: zipcode,
            state: state,
            date: today,
            type: "insert",
          })
          .then((response) => {
            if (response.data.check === "Location Added") {
              alert(response.data.check);
              let locationOBJ = {
                location: location,
                country: country,
                street: street,
                city: city,
                zipcode: zipcode,
                state: state,
              };
              let newLocation = [...locations];
              newLocation.push(locationOBJ);
              setLocations(newLocation);

              setLocationID(null);
              setLocation("");
              setCountry("");
              setStreet("");
              setCity("");
              setZipcode("");
              setState("");
              locationGet();
            } else {
              alert(response.data.check);
            }
          });
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  // post request for updating location
  const locationUpdate = () => {
    try {
      if (
        !(
          location === "" ||
          country === "" ||
          street === "" ||
          city === "" ||
          zipcode === "" ||
          state === ""
        )
      ) {
        axios
          .post(`${BASE_URL}/portLocations/upsert`, {
            location: location,
            country: country,
            street: street,
            city: city,
            zipcode: zipcode,
            state: state,
            date: today,
            type: "update",
          })
          .then((response) => {
            if (response.data.check === "Location Updated") {
              alert(response.data.check);
              let locationOBJ = {
                location: location,
                country: country,
                street: street,
                city: city,
                zipcode: zipcode,
                state: state,
              };
              let newLocation = [...locations];
              newLocation[locationID] = locationOBJ;
              setLocations(newLocation);
              setreadonly(false);
              setLocationID(null);
              setLocation("");
              setCountry("");
              setStreet("");
              setCity("");
              setZipcode("");
              setState("");
              locationGet();
            } else {
              alert(response.data.check);
            }
          });
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const newLocation = () => {
    try {
      setLocationID(null);
      setLocation("");
      setCountry("");
      setStreet("");
      setCity("");
      setZipcode("");
      setState("");
      setreadonly(false);
      locationGet();
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const locationGet = () => {
    try {
      axios
        .post(`${BASE_URL}/portLocations/getall`)
        .then((response) => {
          let locationarray = [];

          for (let x = 0; x < response.data.searchData.length; x += 9) {
            let locationOBJ = {
              location: response.data.searchData[x + 1],
              street: response.data.searchData[x + 2],
              city: response.data.searchData[x + 3],
              zip: response.data.searchData[x + 4],
              state: response.data.searchData[x + 5],
              country: response.data.searchData[x + 6],
            };
            locationarray.push(locationOBJ);
          }
          setLocations(locationarray);
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const locationSet = (data, id) => {
    setLocationID(id);
    setLocation(data.location);
    setCountry(data.country);
    setStreet(data.street);
    setCity(data.city);
    setZipcode(data.zip);
    setState(data.state);
    console.log("");
  };
  function handleClick(selectedRow) {
    setreadonly(true);
    locationSet(locations[selectedRow.tableData.id], selectedRow.tableData.id);
  }
  // get request to make sure user is infact logged in and load data from last login
  useEffect(() => {
    try {
      axios.get(`${BASE_URL}/api/login`).then((response) => {
        if (response.data.loggedIn === true) {
          if (
            response.data.permissions.admin === "true" ||
            response.data.permissions.admin === "1"
          ) {
            setPerms(true);
          }
          if (
            response.data.permissions.dispatch === "true" ||
            response.data.permissions.dispatch === "1"
          ) {
            setPerms(true);
          }
        } else {
          window.location = "/";
        }
      });
      locationGet();
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, []);
  // this function stops the auto reload from react
  // must be called by the form on submit
  function handleSubmit(event) {
    event.preventDefault();
  }
  const theme = createMuiTheme({
    palette: {
      type: "light",
    },
  });
  switch (perms) {
    case true:
      return (
        <ThemeProvider theme={theme}>
          <div idleTimer={idleTimer}>
            <CssBaseline />
            <Grid container spacing={3} style={{ marginTop: 30 }}>
              {/* <IdleTimer ref={idleTimerRef} timeout={timeout} onIdle={onIdle}></IdleTimer> */}
              <Grid item xs={6} sm={6}>
                <MaterialTable
                  icons={tableIcons}
                  title="Port Locations"
                  data={locations}
                  columns={[
                    { field: "location", title: "location" },
                    { field: "country", title: "country" },
                    { field: "street", title: "street" },
                    { field: "city", title: "city" },
                    { field: "state", title: "state" },
                  ]}
                  options={{
                    paging: false,
                    cellStyle: { borderRight: "1px solid " },
                    headerStyle: { borderRight: "1px solid " },
                    maxBodyHeight: 700,
                  }}
                  onRowClick={(evt, selectedRow) => handleClick(selectedRow)}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography component="h1" variant="h5">
                  Locations Page
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    variant="outlined"
                    value={location || ""}
                    required
                    fullWidth
                    id="location"
                    label="location"
                    name="location"
                    style={{ marginTop: 10 }}
                    inputProps={{ readOnly: readonly }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setLocation("");
                      } else {
                        setLocation(e.target.value.trim());
                      }
                    }}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    value={country || ""}
                    required
                    fullWidth
                    id="country"
                    label="country"
                    name="country"
                    style={{ marginTop: 10 }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setCountry("");
                      } else {
                        setCountry(e.target.value);
                      }
                    }}
                  />
                  <TextField
                    variant="outlined"
                    value={street || ""}
                    required
                    fullWidth
                    id="street"
                    label="street"
                    name="street"
                    style={{ marginTop: 10 }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setStreet("");
                      } else {
                        setStreet(e.target.value);
                      }
                    }}
                  />
                  <TextField
                    variant="outlined"
                    value={city || ""}
                    required
                    fullWidth
                    id="city"
                    label="city"
                    name="city"
                    style={{ marginTop: 10 }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setCity("");
                      } else {
                        setCity(e.target.value);
                      }
                    }}
                  />
                  <TextField
                    variant="outlined"
                    value={zipcode || ""}
                    required
                    fullWidth
                    id="zipcode"
                    label="zipcode"
                    name="zipcode"
                    style={{ marginTop: 10 }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setZipcode("");
                      } else {
                        setZipcode(e.target.value);
                      }
                    }}
                  />
                  <TextField
                    variant="outlined"
                    value={state || ""}
                    required
                    fullWidth
                    id="state"
                    label="state"
                    name="state"
                    style={{ marginTop: 10 }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setState("");
                      } else {
                        setState(e.target.value);
                      }
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={locationUpdate}
                  >
                    Update location
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={locationInsert}
                  >
                    Create location
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={newLocation}
                  >
                    New location
                  </Button>
                </form>
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      );
    case false:
      return "you do not have the correct permissions";
    default:
      return "you do not have permissions";
  }
}
