import React, { useEffect, useState, forwardRef, useRef } from "react";
import axios from "axios";
import "./css/board.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {Accordion,AccordionSummary,Grid,Typography,Slide,IconButton,AppBar,Toolbar,Dialog,DialogContent,} from "@mui/material";
import { useIdleTimer } from "react-idle-timer";
import {AddBox,Check,Clear,DeleteOutline,Edit,SaveAlt,FilterList,FirstPage,LastPage,ChevronRight,ChevronLeft,Search,ArrowDownward,Remove,ViewColumn,Close,} from "@mui/icons-material";
import MaterialTable, { MTableBodyRow } from "material-table";
import Dispatch from "./dispatch_form.js";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { confirmAlert } from "react-confirm-alert";
import { BASE_URL } from "./config"; 
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Board() {
  const [events, setEvents] = useState([]);
  const [dropped, setDropped] = useState([]);
  const [readyDispatches, setReadyDispatches] = useState([]);
  const [inProgressDispatches, setInProgressDispatches] = useState([]);
  const [notReadyDispatches, setNotReadyDispatches] = useState([]);
  const [modal, setModal] = useState(false);
  const [dispatchNumber, setDispatchNumber] = useState(false);
  const [perms, setPerms] = useState(false);
  const monthsShort = {Jan: "01",Feb: "02",Mar: "03",Apr: "04",May: "05",Jun: "06",Jul: "07",Aug: "08",Sep: "09",Oct: "10",Nov: "11",Dec: "12",};
  useEffect(() => {
    try {
      axios.get(`${BASE_URL}/api/login`).then((response) => {
        if (response.data.loggedIn === true) {
          if (
            response.data.permissions.admin === "true" ||
            response.data.permissions.admin === "1"
          ) {
            setPerms(true);
          }
          if (
            response.data.permissions.dispatch === "true" ||
            response.data.permissions.dispatch === "1"
          ) {
            setPerms(true);
          }
          axios
            .post(`${BASE_URL}/availability`)
            .then((response) => {
              let readyArray = [];
              let notReadyArray = [];
              if (response.data.check !== "there are no rows") {
                for (let x = 0; x < response.data.check.length; x += 1) {
                  if (response.data.check[x].Availibility) {
                    if (
                      response.data.check[x].Availibility.trim().toLowerCase() === "ready"
                    ) {
                      response.data.check[x].Consignee = String(
                        response.data.check[x].Consignee
                      ).toUpperCase();
                      if (
                        response.data.check[x].PortDate !== undefined &&
                        String(response.data.check[x].PortDate).length > 10
                      ) {
                        let splitDate = response.data.check[x].PortDate.split("-");
                        let splitDate2 = splitDate[2].split("T");
                        response.data.check[x].PortDate = `${splitDate[0]}-${splitDate[1]}-${splitDate2[0]}`;
                      } else {
                        response.data.check[x].PortDate = ``;
                      }
                      readyArray.push(response.data.check[x]);
                    } else {
                      response.data.check[x].Consignee = String(response.data.check[x].Consignee).toUpperCase();
                      notReadyArray.push(response.data.check[x]);
                    }
                  } else {
                    notReadyArray.push(response.data.check[x]);
                  }
                }
              }
              setReadyDispatches(readyArray);
              setNotReadyDispatches(notReadyArray);
            });
          axios
            .post(`${BASE_URL}/board/withDrops`)
            .then((response) => { 
              let dispatchwithdropsobjarray = [];
              let dropObjArray = [];
              let inProgressObjArray = [];
              let eventobjarray = [];
              let dispatchwithdropsobj = {};
              let dropcount = 0;
              for (let x = 0; x < response.data.check.length; x += 18) {
                
                if (response.data.check[x + 12] === "true") {
                  let location = response.data.check[x + 8].trim().toUpperCase() + " - " + response.data.check[x + 9].trim().toUpperCase();
                  let splitoutGate = "";
                  let outGatedate = ``;
                  let deliverydatetime = ``;

                  if (
                    response.data.check[x + 16] !== undefined &&
                    String(response.data.check[x + 16]).length > 10
                  ) {
                    let dateTime = new Date(String(response.data.check[x + 16]));
                    let year = dateTime.getFullYear();
                    let month = dateTime.getMonth() + 1;
                    let day = dateTime.getDate();
                    let hours = dateTime.getHours();
                    let minutes = dateTime.getMinutes();
                    let amOrPm = hours >= 12 ? "pm" : "am";
                    hours = hours % 12 || 12;
                    deliverydatetime = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${amOrPm}`;
                  }
                  if (response.data.check[x + 15] !== undefined && String(response.data.check[x + 15]).length > 10) {
                    splitoutGate = response.data.check[x + 15].split(" ");
                    outGatedate = `${monthsShort[splitoutGate[1]]}-${splitoutGate[2]}`;
                  }
                  dispatchwithdropsobj = {
                    container: response.data.check[x].trim().toUpperCase(),
                    consignee: response.data.check[x + 1].trim().toUpperCase(),
                    bookingnumber: response.data.check[x + 2].trim().toUpperCase(),
                    port_appointment: response.data.check[x + 3].trim().toUpperCase(),
                    port_date: response.data.check[x + 4],
                    lfd: response.data.check[x + 5],
                    dispatchNumber: response.data.check[x + 6].trim(),
                    dropped: response.data.check[x + 14].trim(),
                    outGate: outGatedate.trim(),
                    delivery: deliverydatetime.trim(),
                    ssl: response.data.check[x + 17].trim(),
                    out: {
                      date: response.data.check[x + 7],
                      location: location,
                      locationNote: response.data.check[x + 9].trim().toUpperCase(),
                      driver: response.data.check[x + 10].trim().toUpperCase(),
                      in: response.data.check[x + 11],
                      out: response.data.check[x + 12],
                      dropnumber: response.data.check[x + 13],
                    },
                  };
                } else if (response.data.check[x + 11] === "true") {
                  let location = response.data.check[x + 8].trim().toUpperCase() + " - " + response.data.check[x + 9].trim().toUpperCase();
                  dispatchwithdropsobj.in = {
                    date: response.data.check[x + 7],
                    location: location,
                    locationNote: response.data.check[x + 9].trim().toUpperCase(),
                    driver: response.data.check[x + 10].trim().toUpperCase(),
                    in: response.data.check[x + 11],
                    out: response.data.check[x + 12],
                    dropnumber: response.data.check[x + 13],
                  };
                  dropcount = 0;
                  dispatchwithdropsobjarray.push(dispatchwithdropsobj);
                } else if ( response.data.check[x + 11] === "false" && response.data.check[x + 12] === "false" ) {
                  let location =
                    response.data.check[x + 8].trim().toUpperCase() + " - " + response.data.check[x + 9].trim().toUpperCase();
                    dispatchwithdropsobj[dropcount] = {
                    date: response.data.check[x + 7],
                    location: location,
                    locationNote: response.data.check[x + 9].trim().toUpperCase(),
                    driver: response.data.check[x + 10].trim().toUpperCase(),
                    in: response.data.check[x + 11],
                    out: response.data.check[x + 12],
                    dropnumber: response.data.check[x + 13],
                  };
                  dropcount++;
                } else if (response.data.check[x + 11] === '' || response.data.check[x + 12] === ''){
                  let fulltitle = "";
                  if (response.data.check[x + 5] === "") {
                    fulltitle = response.data.check[x] + "\n" + response.data.check[x + 1] + "\n" + response.data.check[x + 2] + "\n" + response.data.check[x + 3] + "\n" + "LFD: " + "";
                  } else {
                    let date = response.data.check[x + 5].split(" ");
                    fulltitle = response.data.check[x] + "\n" + response.data.check[x + 1] + "\n" + response.data.check[x + 2] + "\n" + response.data.check[x + 3] + "\n" + "LFD: " + date[1] + "-" + date[2] + "-" + date[3];
                  }
                  let obj = {
                    title: fulltitle,
                    start: "2022-01-26",
                    end: "2022-01-26",
                    display: "block",
                    description: response.data.check[x + 6],
                  };
                  let splitDate = response.data.check[x + 4].split(" ");
                    let month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf( splitDate[1] ) / 3 + 1;
                    if (month < 10) {
                      obj.start = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                    } else {
                      obj.start = `${splitDate[3]}-${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-${month}-${splitDate[2]}`;
                    }
                    if(response.data.check[x+2] === '1234567'){
                      console.dir(dispatchwithdropsobj)
                      console.dir(obj)
                    }
                    eventobjarray.push(obj);
                }
                if(response.data.check[x+2] === '1234567'){
                  console.dir(dispatchwithdropsobj)
                }
              }
              for (let x = 0; x < dispatchwithdropsobjarray.length; x++) {

                let dropObj = {};
                if (dispatchwithdropsobjarray[x].in.date === "" || dispatchwithdropsobjarray[x].in.driver === "" || dispatchwithdropsobjarray[x].in.location === "" || dispatchwithdropsobjarray[x].in.location === " - ") {
                  if(dispatchwithdropsobjarray[x].bookingnumber === '1234567'){
                    console.dir(dispatchwithdropsobjarray[x])
                  }
                  let fulltitle = "";
                  if (dispatchwithdropsobjarray[x].lfd === "") {
                    fulltitle = dispatchwithdropsobjarray[x].container + "\n" + dispatchwithdropsobjarray[x].consignee + "\n" + dispatchwithdropsobjarray[x].bookingnumber + "\n" + dispatchwithdropsobjarray[x].port_appointment + "\n" + "LFD: " + "";
                  } else {
                    let date = dispatchwithdropsobjarray[x].lfd.split(" ");
                    fulltitle = dispatchwithdropsobjarray[x].container + "\n" + dispatchwithdropsobjarray[x].consignee + "\n" + dispatchwithdropsobjarray[x].bookingnumber + "\n" + dispatchwithdropsobjarray[x].port_appointment + "\n" + "LFD: " + date[1] + "-" + date[2] + "-" + date[3];
                  }
                  let obj = {
                    title: fulltitle,
                    start: "2022-01-26",
                    end: "2022-01-26",
                    display: "block",
                    description: dispatchwithdropsobjarray[x].dispatchNumber,
                  };
                  if (dispatchwithdropsobjarray[x]["6"]?.date !== undefined && dispatchwithdropsobjarray[x]["6"].date !== "" && dispatchwithdropsobjarray[x]["6"].driver !== "" && dispatchwithdropsobjarray[x]["6"].location !== "" && dispatchwithdropsobjarray[x]["6"].location !== " - ") {
                    if(dispatchwithdropsobjarray[x].bookingnumber === '1234567'){
                      console.dir(6)
                    }
                    let splitDate = dispatchwithdropsobjarray[x]["6"].date.split(" ");
                    let month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(splitDate[1]) / 3 + 1;
                    if (month < 10) {
                      obj.start = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                    } else {
                      obj.start = `${splitDate[3]}-${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-${month}-${splitDate[2]}`;
                    }
                    dropObj = {
                      container: dispatchwithdropsobjarray[x].container,
                      consignee: dispatchwithdropsobjarray[x].consignee,
                      consLoc: dispatchwithdropsobjarray[x].consignee + "  |  " + dispatchwithdropsobjarray[x]["6"].location,
                      bookingnumber: dispatchwithdropsobjarray[x].bookingnumber,
                      port_appointment: dispatchwithdropsobjarray[x].port_appointment,
                      port_date: dispatchwithdropsobjarray[x].port_date,
                      lfd: dispatchwithdropsobjarray[x].lfd,
                      dispatchNumber: dispatchwithdropsobjarray[x].dispatchNumber,
                      date: dispatchwithdropsobjarray[x]["6"].date,
                      location: dispatchwithdropsobjarray[x]["6"].location,
                      driver: dispatchwithdropsobjarray[x]["6"].driver,
                      outGate: dispatchwithdropsobjarray[x].outGate,
                      delivery: dispatchwithdropsobjarray[x].delivery,
                      ssl: dispatchwithdropsobjarray[x].ssl,
                    };
                    if (dispatchwithdropsobjarray[x].dropped === "true") {
                      dropObjArray.push(dropObj);
                    } else {
                      inProgressObjArray.push(dropObj);
                    }
                  } else if (dispatchwithdropsobjarray[x]["5"]?.date !== undefined && dispatchwithdropsobjarray[x]["5"].date !== "" && dispatchwithdropsobjarray[x]["5"].driver !== "" && dispatchwithdropsobjarray[x]["5"].location !== "" && dispatchwithdropsobjarray[x]["5"].location !== " - ") {
                    if(dispatchwithdropsobjarray[x].bookingnumber === '1234567'){
                      console.dir(5)
                    }
                    let splitDate = dispatchwithdropsobjarray[x]["5"].date.split(" ");
                    let month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(splitDate[1]) / 3 + 1;
                    if (month < 10) {
                      obj.start = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                    } else {
                      obj.start = `${splitDate[3]}-${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-${month}-${splitDate[2]}`;
                    }
                    dropObj = {
                      container: dispatchwithdropsobjarray[x].container,
                      consignee: dispatchwithdropsobjarray[x].consignee,
                      consLoc: dispatchwithdropsobjarray[x].consignee + "  |  " + dispatchwithdropsobjarray[x]["5"].location,
                      bookingnumber: dispatchwithdropsobjarray[x].bookingnumber,
                      port_appointment: dispatchwithdropsobjarray[x].port_appointment,
                      port_date: dispatchwithdropsobjarray[x].port_date,
                      lfd: dispatchwithdropsobjarray[x].lfd,
                      dispatchNumber: dispatchwithdropsobjarray[x].dispatchNumber,
                      date: dispatchwithdropsobjarray[x]["5"].date,
                      location: dispatchwithdropsobjarray[x]["5"].location,
                      driver: dispatchwithdropsobjarray[x]["5"].driver,
                      outGate: dispatchwithdropsobjarray[x].outGate,
                      delivery: dispatchwithdropsobjarray[x].delivery,
                      ssl: dispatchwithdropsobjarray[x].ssl,
                    };
                    if (dispatchwithdropsobjarray[x].dropped === "true") {
                      dropObjArray.push(dropObj);
                    } else {
                      inProgressObjArray.push(dropObj);
                    }
                  } else if (dispatchwithdropsobjarray[x]["4"]?.date !== undefined && dispatchwithdropsobjarray[x]["4"].date !== "" && dispatchwithdropsobjarray[x]["4"].driver !== "" && dispatchwithdropsobjarray[x]["4"].location !== "" && dispatchwithdropsobjarray[x]["4"].location !== " - ") {
                    if(dispatchwithdropsobjarray[x].bookingnumber === '1234567'){
                      console.dir(4)
                    }
                    let splitDate = dispatchwithdropsobjarray[x]["4"].date.split(" ");
                    let month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(splitDate[1]) / 3 + 1;
                    if (month < 10) {
                      obj.start = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                    } else {
                      obj.start = `${splitDate[3]}-${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-${month}-${splitDate[2]}`;
                    }
                    dropObj = {
                      container: dispatchwithdropsobjarray[x].container,
                      consignee: dispatchwithdropsobjarray[x].consignee,
                      consLoc: dispatchwithdropsobjarray[x].consignee + "  |  " + dispatchwithdropsobjarray[x]["4"].location,
                      bookingnumber: dispatchwithdropsobjarray[x].bookingnumber,
                      port_appointment: dispatchwithdropsobjarray[x].port_appointment,
                      port_date: dispatchwithdropsobjarray[x].port_date,
                      lfd: dispatchwithdropsobjarray[x].lfd,
                      dispatchNumber: dispatchwithdropsobjarray[x].dispatchNumber,
                      date: dispatchwithdropsobjarray[x]["4"].date,
                      location: dispatchwithdropsobjarray[x]["4"].location,
                      driver: dispatchwithdropsobjarray[x]["4"].driver,
                      outGate: dispatchwithdropsobjarray[x].outGate,
                      delivery: dispatchwithdropsobjarray[x].delivery,
                      ssl: dispatchwithdropsobjarray[x].ssl,
                    };
                    if (dispatchwithdropsobjarray[x].dropped === "true") {
                      dropObjArray.push(dropObj);
                    } else {
                      inProgressObjArray.push(dropObj);
                    }
                  } else if (dispatchwithdropsobjarray[x]["3"]?.date !== undefined && dispatchwithdropsobjarray[x]["3"].date !== "" && dispatchwithdropsobjarray[x]["3"].driver !== "" && dispatchwithdropsobjarray[x]["3"].location !== "" && dispatchwithdropsobjarray[x]["3"].location !== " - ") {
                    if(dispatchwithdropsobjarray[x].bookingnumber === '1234567'){
                      console.dir(3)
                    }
                    let splitDate = dispatchwithdropsobjarray[x]["3"].date.split(" ");
                    let month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(splitDate[1]) / 3 + 1;
                    if (month < 10) {
                      obj.start = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                    } else {
                      obj.start = `${splitDate[3]}-${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-${month}-${splitDate[2]}`;
                    }
                    dropObj = {
                      container: dispatchwithdropsobjarray[x].container,
                      consignee: dispatchwithdropsobjarray[x].consignee,
                      consLoc: dispatchwithdropsobjarray[x].consignee + "  |  " + dispatchwithdropsobjarray[x]["3"].location,
                      bookingnumber: dispatchwithdropsobjarray[x].bookingnumber,
                      port_appointment: dispatchwithdropsobjarray[x].port_appointment,
                      port_date: dispatchwithdropsobjarray[x].port_date,
                      lfd: dispatchwithdropsobjarray[x].lfd,
                      dispatchNumber: dispatchwithdropsobjarray[x].dispatchNumber,
                      date: dispatchwithdropsobjarray[x]["3"].date,
                      location: dispatchwithdropsobjarray[x]["3"].location,
                      driver: dispatchwithdropsobjarray[x]["3"].driver,
                      outGate: dispatchwithdropsobjarray[x].outGate,
                      delivery: dispatchwithdropsobjarray[x].delivery,
                      ssl: dispatchwithdropsobjarray[x].ssl,
                    };
                    if (dispatchwithdropsobjarray[x].dropped === "true") {
                      dropObjArray.push(dropObj);
                    } else {
                      inProgressObjArray.push(dropObj);
                    }
                  } else if (dispatchwithdropsobjarray[x]["2"]?.date !== undefined && dispatchwithdropsobjarray[x]["2"].date !== "" && dispatchwithdropsobjarray[x]["2"].driver !== "" && dispatchwithdropsobjarray[x]["2"].location !== "" && dispatchwithdropsobjarray[x]["2"].location !== " - ") {
                    if(dispatchwithdropsobjarray[x].bookingnumber === '1234567'){
                      console.dir(2)
                    }
                    let splitDate = dispatchwithdropsobjarray[x]["2"].date.split(" ");
                    let month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(splitDate[1]) / 3 + 1;
                    if (month < 10) {
                      obj.start = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                    } else {
                      obj.start = `${splitDate[3]}-${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-${month}-${splitDate[2]}`;
                    }
                    dropObj = {
                      container: dispatchwithdropsobjarray[x].container,
                      consignee: dispatchwithdropsobjarray[x].consignee,
                      consLoc: dispatchwithdropsobjarray[x].consignee + "  |  " + dispatchwithdropsobjarray[x]["2"].location,
                      bookingnumber: dispatchwithdropsobjarray[x].bookingnumber,
                      port_appointment: dispatchwithdropsobjarray[x].port_appointment,
                      port_date: dispatchwithdropsobjarray[x].port_date,
                      lfd: dispatchwithdropsobjarray[x].lfd,
                      dispatchNumber: dispatchwithdropsobjarray[x].dispatchNumber,
                      date: dispatchwithdropsobjarray[x]["2"].date,
                      location: dispatchwithdropsobjarray[x]["2"].location,
                      driver: dispatchwithdropsobjarray[x]["2"].driver,
                      outGate: dispatchwithdropsobjarray[x].outGate,
                      delivery: dispatchwithdropsobjarray[x].delivery,
                      ssl: dispatchwithdropsobjarray[x].ssl,
                    };
                    if (dispatchwithdropsobjarray[x].dropped === "true") {
                      dropObjArray.push(dropObj);
                    } else {
                      inProgressObjArray.push(dropObj);
                    }
                  } else if (dispatchwithdropsobjarray[x]["1"]?.date !== undefined && dispatchwithdropsobjarray[x]["1"].date !== "" && dispatchwithdropsobjarray[x]["1"].driver !== "" && dispatchwithdropsobjarray[x]["1"].location !== "" && dispatchwithdropsobjarray[x]["1"].location !== " - ") {
                    if(dispatchwithdropsobjarray[x].bookingnumber === '1234567'){
                      console.dir(1)
                    }
                    let splitDate =
                      dispatchwithdropsobjarray[x]["1"].date.split(" ");
                    let month =
                      "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(
                        splitDate[1]
                      ) /
                        3 +
                      1;
                    if (month < 10) {
                      obj.start = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                    } else {
                      obj.start = `${splitDate[3]}-${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-${month}-${splitDate[2]}`;
                    }
                    dropObj = {
                      container: dispatchwithdropsobjarray[x].container,
                      consignee: dispatchwithdropsobjarray[x].consignee,
                      consLoc: dispatchwithdropsobjarray[x].consignee + "  |  " + dispatchwithdropsobjarray[x]["1"].location,
                      bookingnumber: dispatchwithdropsobjarray[x].bookingnumber,
                      port_appointment:
                        dispatchwithdropsobjarray[x].port_appointment,
                      port_date: dispatchwithdropsobjarray[x].port_date,
                      lfd: dispatchwithdropsobjarray[x].lfd,
                      dispatchNumber:
                        dispatchwithdropsobjarray[x].dispatchNumber,
                      date: dispatchwithdropsobjarray[x]["1"].date,
                      location: dispatchwithdropsobjarray[x]["1"].location,
                      driver: dispatchwithdropsobjarray[x]["1"].driver,
                      outGate: dispatchwithdropsobjarray[x].outGate,
                      delivery: dispatchwithdropsobjarray[x].delivery,
                      ssl: dispatchwithdropsobjarray[x].ssl,
                    };
                    if (dispatchwithdropsobjarray[x].dropped === "true") {
                      dropObjArray.push(dropObj);
                    } else {
                      inProgressObjArray.push(dropObj);
                    }
                  } else if (dispatchwithdropsobjarray[x]["0"]?.date !== undefined && dispatchwithdropsobjarray[x]["0"].date !== "" && dispatchwithdropsobjarray[x]["0"].driver !== "" && dispatchwithdropsobjarray[x]["0"].location !== "" && dispatchwithdropsobjarray[x]["0"].location !== " - ") {
                    if(dispatchwithdropsobjarray[x].bookingnumber === '1234567'){
                      console.dir(0)
                    }
                    let splitDate =
                      dispatchwithdropsobjarray[x]["0"].date.split(" ");
                    let month =
                      "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(
                        splitDate[1]
                      ) /
                        3 +
                      1;
                    if (month < 10) {
                      obj.start = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                    } else {
                      obj.start = `${splitDate[3]}-${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-${month}-${splitDate[2]}`;
                    }
                    dropObj = {
                      container: dispatchwithdropsobjarray[x].container,
                      consignee: dispatchwithdropsobjarray[x].consignee,
                      consLoc: dispatchwithdropsobjarray[x].consignee + "  |  " + dispatchwithdropsobjarray[x]["0"].location,
                      bookingnumber: dispatchwithdropsobjarray[x].bookingnumber,
                      port_appointment:
                        dispatchwithdropsobjarray[x].port_appointment,
                      port_date: dispatchwithdropsobjarray[x].port_date,
                      lfd: dispatchwithdropsobjarray[x].lfd,
                      dispatchNumber:
                        dispatchwithdropsobjarray[x].dispatchNumber,
                      date: dispatchwithdropsobjarray[x]["0"].date,
                      location: dispatchwithdropsobjarray[x]["0"].location,
                      driver: dispatchwithdropsobjarray[x]["0"].driver,
                      outGate: dispatchwithdropsobjarray[x].outGate,
                      delivery: dispatchwithdropsobjarray[x].delivery,
                      ssl: dispatchwithdropsobjarray[x].ssl,
                    };
                    if (dispatchwithdropsobjarray[x].dropped === "true") {
                      dropObjArray.push(dropObj);
                    } else {
                      inProgressObjArray.push(dropObj);
                    }
                  } else if (dispatchwithdropsobjarray[x].out.date !== "" && dispatchwithdropsobjarray[x].out.driver !== "" && dispatchwithdropsobjarray[x].out.location !== "" && dispatchwithdropsobjarray[x].out.location !== " - ") {
                    if(dispatchwithdropsobjarray[x].bookingnumber === '1234567'){
                      console.dir("out")
                    }
                    let splitDate =
                      dispatchwithdropsobjarray[x].out.date.split(" ");
                    let month =
                      "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(
                        splitDate[1]
                      ) /
                        3 +
                      1;
                    if (month < 10) {
                      obj.start = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                    } else {
                      obj.start = `${splitDate[3]}-${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-${month}-${splitDate[2]}`;
                    }
                    dropObj = {
                      container: dispatchwithdropsobjarray[x].container,
                      consignee: dispatchwithdropsobjarray[x].consignee,
                      consLoc: dispatchwithdropsobjarray[x].consignee + "  |  " + dispatchwithdropsobjarray[x].out.location,
                      bookingnumber: dispatchwithdropsobjarray[x].bookingnumber,
                      port_appointment:
                        dispatchwithdropsobjarray[x].port_appointment,
                      port_date: dispatchwithdropsobjarray[x].port_date,
                      lfd: dispatchwithdropsobjarray[x].lfd,
                      dispatchNumber:
                        dispatchwithdropsobjarray[x].dispatchNumber,
                      date: dispatchwithdropsobjarray[x].out.date,
                      location: dispatchwithdropsobjarray[x].out.location,
                      driver: dispatchwithdropsobjarray[x].out.driver,
                      outGate: dispatchwithdropsobjarray[x].outGate,
                      delivery: dispatchwithdropsobjarray[x].delivery,
                      ssl: dispatchwithdropsobjarray[x].ssl,
                    };
                    if (dispatchwithdropsobjarray[x].dropped === "true") {
                      dropObjArray.push(dropObj);
                    } else {
                      inProgressObjArray.push(dropObj);
                    }
                  } else if ( dispatchwithdropsobjarray[x].port_date !== "" && dispatchwithdropsobjarray[x].dropped !== "true" ) {
                    if(dispatchwithdropsobjarray[x].bookingnumber === '1234567'){
                      console.dir("event")
                    }
                    let splitDate =
                      dispatchwithdropsobjarray[x].port_date.split(" ");
                    let month =
                      "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(
                        splitDate[1]
                      ) /
                        3 +
                      1;
                    if (month < 10) {
                      obj.start = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-0${month}-${splitDate[2]}`;
                    } else {
                      obj.start = `${splitDate[3]}-${month}-${splitDate[2]}`;
                      obj.end = `${splitDate[3]}-${month}-${splitDate[2]}`;
                    }
                    if(dispatchwithdropsobjarray[x].bookingnumber === '1234567'){
                      console.dir(obj)
                    }
                    eventobjarray.push(obj);
                  }
                }
              }
              let uniqueDescriptions = {};
              let uniqueEventArray = eventobjarray.filter(obj => {
                if (!uniqueDescriptions[obj.description]) {
                  uniqueDescriptions[obj.description] = true;
                  return true;
                }
                return false;
              });
              setEvents(uniqueEventArray);
              dropObjArray.sort((a, b) => {
                const driverA = a.consLoc.toUpperCase(); 
                const driverB = b.consLoc.toUpperCase();
                if (driverA < driverB) {
                  return -1;
                }
                if (driverA > driverB) {
                  return 1;
                }
                return 0;
              });
              setDropped(dropObjArray);
              inProgressObjArray.sort((a, b) => {
                const driverA = a.driver.toUpperCase();
                const driverB = b.driver.toUpperCase();
                if (driverA < driverB) {
                  return -1; 
                }
                if (driverA > driverB) {
                  return 1; 
                }
                return 0; 
              });
              setInProgressDispatches(inProgressObjArray);
            });
        } else {
          window.location = "/";
        }
      });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, []);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const toggleModal = () => {
    setModal(!modal);
  };
  const boardOptions = {
    title: "Leaving Page",
    message:
      "Any unsaved changes will be lost. Are you sure you would like to leave this page?",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          setModal(!modal);
        },
      },
      {
        label: "No",
        onClick: () => {
          setModal(true);
        },
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlayAlert",
    class: "overlayAlert",
  };
  const toggleModalClose = () => {
    confirmAlert(boardOptions);
  };
  const handleEventDoubleClick = (info) => {
    console.log(
      `Double-clicked on event: ${info.event.extendedProps.description}`
    );
    setDispatchNumber(info.event.extendedProps.description);
    toggleModal();
  };
  const onIdle = () => {
    try {
      const location = window.location.href.split("/");
      axios
        .post(`${BASE_URL}/logout`, {
          location: location[3],
        })
        .then((response) => {
          window.location = "/";
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const idleTimerRef = useRef(null);
  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: 60 * 15 * 1000,
    onIdle: onIdle,
  });
  switch (perms) {
    case true:
      return (
        <div idleTimer={idleTimer}>
          <Grid container spacing={1}>
            <Grid item xs={4} sm={4}>
              <p>All In Progress Dispatches</p>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridWeek"
                headerToolbar={{
                  center: "dayGridMonth,dayGridWeek,dayGridDay",
                }}
                events={events}
                eventColor="transparent"
                eventTextColor="black"
                nowIndicator
                eventDidMount={(eventInfo) => {
                  const eventEl = eventInfo.el;
                  eventEl.style.border = "2px solid black";
                  eventEl.addEventListener("dblclick", () => {
                    handleEventDoubleClick(eventInfo);
                  });
                }}
                height={600}
                eventDisplay="block"
              />
            </Grid>
            <Grid item xs={8 / 2}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Ready Table:</Typography>
                </AccordionSummary>
                <MaterialTable
                  icons={tableIcons}
                  title="Ready"
                  data={readyDispatches}
                  columns={[
                    { field: "ContainerNumber", title: "Cont" },
                    { field: "BookingNumber", title: "Book" },
                    { field: "Consignee", title: "Cons" },
                    { field: "PortDate", title: "Date" },
                  ]}
                  options={{
                    paging: false,
                    headerStyle: {
                      borderColor: "Grey",
                      backgroundColor: "#3f51b5",
                      color: "Black",
                      borderRight: "0.5px solid",
                      borderLeft: "0.5px solid",
                    },
                    cellStyle: {
                      height: "40",
                      borderColor: "Grey",
                      color: "Black",
                      border: "0.5px solid",
                      padding: "0",
                      fontSize: 13,
                    },
                    rowStyle: { overflowWrap: "break-word" },
                    tableLayout: "fixed",
                    maxBodyHeight: 350,
                  }}
                  components={{
                    Row: (props) => (
                      <MTableBodyRow
                        {...props}
                        onDoubleClick={(e) => {
                          setDispatchNumber(props.data.DispatchNumber);
                          toggleModal();
                        }}
                      />
                    ),
                  }}
                  onRowClick={(e) => console.log("")}
                />
              </Accordion>
              <Grid item xs={12}>
                <MaterialTable
                  icons={tableIcons}
                  title="In Progress"
                  data={inProgressDispatches}
                  columns={[
                    { field: "driver", title: "Driver" },
                    { field: "container", title: "Container" },
                    { field: "bookingnumber", title: "Booking" },
                    { field: "consignee", title: "Consignee" },
                  ]}
                  options={{
                    paging: false,
                    headerStyle: {
                      borderColor: "Grey",
                      backgroundColor: "#3f51b5",
                      color: "Black",
                      borderRight: "0.5px solid",
                      borderLeft: "0.5px solid",
                    },
                    cellStyle: {
                      height: "40",
                      borderColor: "Grey",
                      color: "Black",
                      border: "0.5px solid",
                      padding: "0",
                      fontSize: 13,
                    },
                    rowStyle: { overflowWrap: "break-word" },
                    tableLayout: "fixed",
                    maxBodyHeight: 700,
                  }}
                  components={{
                    Row: (props) => (
                      <MTableBodyRow
                        {...props}
                        onDoubleClick={(e) => {
                          setDispatchNumber(props.data.dispatchNumber);
                          toggleModal();
                        }}
                      />
                    ),
                  }}
                  onRowClick={(e) => console.log("")}
                />
              </Grid>
            </Grid>
            <Grid item xs={8 / 2}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography>Not Ready Table:</Typography>
                </AccordionSummary>
                <MaterialTable
                  icons={tableIcons}
                  title="Not Ready"
                  data={notReadyDispatches}
                  columns={[
                    { field: "ContainerNumber", title: "Container" },
                    { field: "BookingNumber", title: "Booking" },
                    { field: "Consignee", title: "Consignee" },
                  ]}
                  options={{
                    paging: false,
                    headerStyle: {
                      borderColor: "Grey",
                      backgroundColor: "#3f51b5",
                      color: "Black",
                      borderRight: "0.5px solid",
                      borderLeft: "0.5px solid",
                    },
                    cellStyle: {
                      height: "40",
                      borderColor: "Grey",
                      color: "Black",
                      border: "0.5px solid",
                      padding: "0",
                      fontSize: 13,
                    },
                    rowStyle: { overflowWrap: "break-word" },
                    tableLayout: "fixed",
                    maxBodyHeight: 350,
                  }}
                  components={{
                    Row: (props) => (
                      <MTableBodyRow
                        {...props}
                        onDoubleClick={(e) => {
                          setDispatchNumber(props.data.DispatchNumber);
                          toggleModal();
                        }}
                      />
                    ),
                  }}
                  onRowClick={(e) => console.log("")}
                />
              </Accordion>
              <MaterialTable
                icons={tableIcons}
                title="Drop"
                data={dropped}
                columns={[
                  {
                    field: "consLoc",
                    title: "",
                    defaultGroupOrder: 0,
                    width: "20%",
                  },
                  { field: "container", title: "Container", width: "20%" },
                  { field: "outGate", title: "OutGate", width: "20%" },
                  { field: "delivery", title: "Delivery", width: "20%" },
                  { field: "ssl", title: "SSL", width: "20%" },
                ]}
                options={{
                  paging: false,
                  headerStyle: {
                    borderColor: "Grey",
                    backgroundColor: "#3f51b5",
                    color: "Black",
                    borderRight: "0.5px solid",
                    borderLeft: "0.5px solid",
                  },
                  cellStyle: {
                    height: "40",
                    borderColor: "Grey",
                    color: "Black",
                    border: "0.5px solid",
                    padding: "0",
                    fontSize: 13,
                  },
                  rowStyle: { overflowWrap: "break-word" },
                  tableLayout: "fixed",
                  grouping: true,
                  maxBodyHeight: 700,
                  defaultExpanded: true,
                }}
                components={{
                  Row: (props) => (
                    <MTableBodyRow
                      {...props}
                      onDoubleClick={(e) => {
                        setDispatchNumber(props.data.dispatchNumber);
                        toggleModal();
                      }}
                    />
                  ),
                }}
                onRowClick={(e) => console.log("")}
              />
            </Grid>
          </Grid>
          <Dialog
            fullScreen
            open={modal}
            onClose={toggleModal}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar className="dialogboard">
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={toggleModalClose}
                  aria-label="close"
                >
                  <Close align="right" />
                </IconButton>
                <Typography
                  align="right"
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Dispatch
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent className="dialogbg">
              <Dispatch dispatchNumber={dispatchNumber} />
            </DialogContent>
          </Dialog>
        </div>
      );
    case false:
      return "you do not have the correct permissions";
    default:
      return "you do not have permissions";
  }
}
