import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  forwardRef,
} from "react";
import axios from "axios";
import errorHandle from "./error_handleing.js";
import MaterialTable from "material-table";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
  CssBaseline,
  Typography,
  Grid,
  FormControlLabel,
  FormControl,
  createMuiTheme,
  ThemeProvider,
  TextField,
  Button,
  Checkbox,
  Select,
} from "@mui/material";
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as XLSX from "xlsx";
import { useIdleTimer } from "react-idle-timer";
import { BASE_URL, companyName } from "./config"; // Import the BASE_URL variable

window.onerror = function (msg, url, lineNo, columnNo, error) {
  errorHandle(msg, lineNo, columnNo, error);
  alert(
    "msg: ",
    msg,
    "\nlineNo: ",
    lineNo,
    "\ncolumnNo: ",
    columnNo,
    "\nerror: ",
    error
  );
};
export default function Admin() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [driverName, setDriverName] = useState(null);
  const [drops, setDrops] = useState([]);
  const [chequeNumber, setChequeNumber] = useState(null);
  const [totalCosts, setTotalCosts] = useState(0);
  const [totalRemaining, setTotalRemaining] = useState(0);
  const [allDriverCheckBox, setAllDriverCheckBox] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [perms, setPerms] = useState(false);
  const [tableInfo, setTableInfo] = useState("single");
  const invoiceTableColumns = {
    single: [
      { field: "Driver", title: "Driver" },
      { field: "WorkDate", title: "Work Date" },
      { field: "Dispatch", title: "Dispatch" },
      { field: "invoiceNumber", title: "Invoice" },
      { field: "Container", title: "Container" },
      { field: "From", title: "From" },
      { field: "To", title: "To" },
      { field: "Rate", title: "Rate" },
      { field: "Toll", title: "Toll" },
      { field: "Extra", title: "Extra" },
      { field: "Fsc", title: "Fsc" },
      { field: "FscPercent", title: "Fsc%" },
      { field: "Note", title: "Note" },
      { field: "Cheque", title: "Cheque" },
    ],
    all: [
      { field: "Driver", title: "Driver" },
      { field: "TotalRate", title: "TotalRate" },
      { field: "TotalTolls", title: "TotalTolls" },
      { field: "TotalExtra", title: "TotalExtra" },
      { field: "TotalFsc", title: "TotalFsc" },
      { field: "GrandTotalDue", title: "GrandTotalDue" },
    ],
  };
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  axios.defaults.withCredentials = true;
  useEffect(() => {
    try {
      axios
        .get(`${BASE_URL}/api/login`)
        .then((response) => {
          if (response.data.loggedIn === true) {
            if (
              response.data.permissions.admin === "true" ||
              response.data.permissions.admin === "1"
            ) {
              setPerms(true);
            }
            if (
              response.data.permissions.payroll === "true" ||
              response.data.permissions.payroll === "1"
            ) {
              setPerms(true);
            }
            getDrivers();
          } else {
            window.location = "/";
          }
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, []);
  const theme = createMuiTheme({
    palette: {
      type: "light",
    },
  });
  const formatDate = (date) => {
    if (date !== undefined) {
      const monthMap = new Map([
        ["Jan", "01"],
        ["Feb", "02"],
        ["Mar", "03"],
        ["Apr", "04"],
        ["May", "05"],
        ["Jun", "06"],
        ["Jul", "07"],
        ["Aug", "08"],
        ["Sep", "09"],
        ["Oct", "10"],
        ["Nov", "11"],
        ["Dec", "12"],
      ]);

      let splitDate = String(date).split(" ");
      let dateString = splitDate[1] + "/" + splitDate[2] + "/" + splitDate[3];
      let monthAbbreviation = dateString.substr(0, 3);
      let modifiedDateString = dateString.replace(monthAbbreviation, monthMap.get(monthAbbreviation));
      return modifiedDateString;
    } else {
      return null;
    }
  };
  const formatDatequery = (date) => {
    if (date !== undefined) {
      let splitDate = String(date).split(" ");
      return splitDate[3] + "-" + splitDate[1] + "-" + splitDate[2];
    } else {
      return null;
    }
  };
  const toInt = (number) => {
    const parsed = parseInt(number);
    if (isNaN(parsed)) {
      return 0;
    }
    return parsed;
  };
  const toFloatWithTwoDecimalPlaces = (number) => {
    const parsed = parseFloat(number);
    if (isNaN(parsed)) {
      return 0;
    }
    return parseFloat(parsed.toFixed(2));
  };
  const getDrivers = () => {
    axios.post(`${BASE_URL}/drivers`).then((response) => {
      let newdrivers = [];
      for (let x = 0; x < response.data.check.length; x++) {
        newdrivers.push(response.data.check[x].trim().toUpperCase());
      }
      setDrivers(newdrivers);
    });
  };
  const getDrops = () => {
    try {
      if (startDate === null || endDate === null) {
        alert("please enter a start and end date");
      } else if (
        (driverName === null || driverName === "") &&
        (allDriverCheckBox === false || allDriverCheckBox === 0)
      ) {
        alert("please enter a driver Name");
      } else if (allDriverCheckBox === true || allDriverCheckBox === 1) {
        axios
          .post(`${BASE_URL}/payroll/allDrivers`, {
            start: formatDatequery(startDate),
            end: formatDatequery(endDate),
            driver: driverName,
          })
          .then((response) => {
            let dropslist = [];
            let totalDue = 0;
            let totalRemain = 0;
            for (let x = 0; x < response.data.searchData.length; x += 6) {
              let dropOBJ = {
                Driver: response.data.searchData[x].toUpperCase(),
                TotalExtra: "$" + response.data.searchData[x + 1].toUpperCase(),
                TotalTolls: "$" + response.data.searchData[x + 2].toUpperCase(),
                TotalRate: "$" + response.data.searchData[x + 3].toUpperCase(),
                TotalFsc: "$" + response.data.searchData[x + 4].toUpperCase(),
                GrandTotalDue: response.data.searchData[x + 5].toUpperCase(),
              };
              totalDue += toFloatWithTwoDecimalPlaces(dropOBJ.GrandTotalDue);
              dropOBJ.GrandTotalDue = "$" + response.data.searchData[x + 5].toUpperCase()
              dropslist.push(dropOBJ);
            }
            setDrops(dropslist);
            setTableInfo("all");
            if (dropslist.length < 1) {
              alert("no drops found for that date range");
            } else {
              setTotalCosts("$" + totalDue);
              setTotalRemaining("$" + totalRemain);
            }
          });
      } else {
        axios
          .post(`${BASE_URL}/payroll/`, {
            start: formatDatequery(startDate),
            end: formatDatequery(endDate),
            driver: driverName.trim().toLowerCase(),
          })
          .then((response) => {
            let dropslist = [];
            let totalDue = 0;
            let totalRemain = 0;
            if (response.data.searchData.length < 1) {
              alert("no drops found please check driver name");
            } else {
              for (let x = 0; x < response.data.searchData.length; x += 23) {
                let dropOBJ = {
                  uid: response.data.searchData[x],
                  Driver: response.data.searchData[x + 5].toUpperCase(),
                  WorkDate: formatDate(response.data.searchData[x + 2]),
                  Dispatch: response.data.searchData[x + 1],
                  Container: response.data.searchData[x + 19].toUpperCase(),
                  From: response.data.searchData[x + 22].toUpperCase(),
                  To: response.data.searchData[x + 3].toUpperCase(),
                  Rate: response.data.searchData[x + 9],
                  Toll: response.data.searchData[x + 8],
                  Extra: response.data.searchData[x + 7],
                  Fsc: 
                    (toInt(response.data.searchData[x + 9]) *
                    (toInt(response.data.searchData[x + 18]) / 100)),
                  FscPercent: response.data.searchData[x + 18],
                  Note: response.data.searchData[x + 6].toUpperCase(),
                  Cheque: response.data.searchData[x + 10],
                  totalDue: "",
                  totalRemain: "",
                  invoiceNumber: response.data.searchData[x + 20],
                  invoiceUID: response.data.searchData[x + 21],
                };
                if (dropOBJ.invoiceNumber === null) {
                  dropOBJ.invoiceNumber = dropOBJ.invoiceUID;
                }
                if (dropOBJ.From === "NULL") {
                  dropOBJ.From = "";
                }
                if (
                  dropOBJ.FscPercent === null ||
                  dropOBJ.FscPercent === "null"
                ) {
                  dropOBJ.FscPercent = 0;
                }
                console.dir(dropOBJ)
                totalDue +=
                toFloatWithTwoDecimalPlaces(dropOBJ.Rate) +
                toFloatWithTwoDecimalPlaces(dropOBJ.Toll) +
                toFloatWithTwoDecimalPlaces(dropOBJ.Extra) +
                toFloatWithTwoDecimalPlaces(dropOBJ.Fsc);
                console.dir(totalDue)

                if (
                  dropOBJ.Cheque === "" ||
                  dropOBJ.Cheque === null ||
                  dropOBJ.Cheque === "null"
                ) {
                  totalRemain +=
                  toFloatWithTwoDecimalPlaces(dropOBJ.Rate) +
                  toFloatWithTwoDecimalPlaces(dropOBJ.Toll) +
                  toFloatWithTwoDecimalPlaces(dropOBJ.Extra) +
                  toFloatWithTwoDecimalPlaces(dropOBJ.Fsc);
                  dropOBJ.Cheque = "";
                }
                if (dropOBJ.Note === null || dropOBJ.Note === "NULL") {
                  dropOBJ.Note = "";
                }
                dropOBJ.Rate = "$" + response.data.searchData[x + 9]
                dropOBJ.Toll = "$" + response.data.searchData[x + 8]
                dropOBJ.Extra = "$" + response.data.searchData[x + 7]
                dropOBJ.Fsc = "$" + (toFloatWithTwoDecimalPlaces(response.data.searchData[x + 9]) * (toFloatWithTwoDecimalPlaces(response.data.searchData[x + 18]) / 100))
                dropslist.push(dropOBJ);
              }
              
              dropslist[0].totalDue = totalDue;
              dropslist[0].totalRemain = totalRemain;
              
              setDrops(dropslist);
              setTableInfo("single");
              if (dropslist.length < 1) {
                alert("no drops found please check driver name");
              } else {
                setTotalCosts("$" + totalDue);
                setTotalRemaining("$" + totalRemain);
              }
            }
          });
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const applyCheques = () => {
    try {
      if (allDriverCheckBox) {
        alert("search by driver before trying to apply cheque");
      } else if (chequeNumber !== "" && chequeNumber !== null) {
        let dropids = [];
        drops.forEach((drop) => {
          if (drop.Cheque === "" || drop.Cheque === null) {
            dropids.push(drop.uid);
          }
        });
        if (dropids.length < 1) {
          alert("no drops to add cheque to");
        } else {
          axios
            .post(`${BASE_URL}/payroll/applyCheque`, {
              cheque: chequeNumber,
              dropids: dropids,
            })
            .then((response) => {
              let newDrops = [...drops];
              newDrops.forEach((drop) => {
                if (drop.Cheque === "" || drop.Cheque === null) {
                  drop.Cheque = chequeNumber;
                }
              });
              setDrops(newDrops);
              document.getElementById("chequenumber").value = "";
            });
        }
      } else {
        alert("please enter a cheque number");
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const downloadExcel = () => {
    const newData = drops.map((row) => {
      delete row.tableData;
      delete row.uid;
      return row;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "drops");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "drops.xlsx");
  };
  const toggleCheckBox = (checked) => {
    setAllDriverCheckBox(checked);
    setDriverName(null);
  };
  const onIdle = () => {
    try {
      const location = window.location.href.split("/");
      axios
        .post(`${BASE_URL}/logout`, {
          location: location[3],
        })
        .then((response) => {
          window.location = "/";
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const idleTimerRef = useRef(null);
  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: 60 * 15 * 1000,
    onIdle: onIdle,
  });
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 7,
      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    page2: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    titleContainer: {
      flexDirection: "row",
      marginTop: 12,
    },
    reportTitle: {
      color: "#61dafb",
      letterSpacing: 4,
      fontSize: 25,
      textAlign: "center",
      textTransform: "uppercase",
    },
    reportTitle2: {
      color: "#61dafb",
      letterSpacing: 2,
      fontSize: 18,
      textAlign: "center",
      textTransform: "uppercase",
    },
    remitlabel: {
      width: "100%",
    },
    label: {
      width: "33%",
      borderBottomColor: "#bff0fd",
      borderTopColor: "#bff0fd",
      borderLeftColor: "#bff0fd",
      borderRightColor: "#bff0fd",
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
    },
    direction: {
      flexDirection: "row",
    },
    container: {
      flexDirection: "row",
      borderBottomColor: "#bff0fd",
      backgroundColor: "#bff0fd",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      textAlign: "center",
      fontStyle: "bold",
    },
    description: {
      width: "10%",
      textAlign: "left",
      borderRightColor: "#90e5fc",
      borderRightWidth: 0,
      paddingLeft: 8,
    },
    weight: {
      width: "10%",
      borderRightColor: "#90e5fc",
      borderRightWidth: 0,
      textAlign: "right",
      paddingRight: 8,
    },
    charges: {
      width: "5%",
      borderRightColor: "#90e5fc",
      borderRightWidth: 0,
      textAlign: "right",
      paddingRight: 8,
    },
    amount: {
      width: "13.75%",
      borderRightColor: "#90e5fc",
      textAlign: "left",
      paddingRight: 8,
    },
    row: {
      flexDirection: "row",
      borderBottomColor: "#bff0fd",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      fontStyle: "bold",
    },
    footerTitleContainer: {
      flexDirection: "row",
      textAlign: "center",
      marginTop: 12,
    },
    footerReportTitle: {
      fontSize: 12,
      textAlign: "center",
      textTransform: "uppercase",
    },
  });
  const MyDocument = () => (
    <Document rotate={90}>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View>
          <Text style={styles.remitlabel}>{companyName} {driverName}</Text>
          <Text style={styles.remitlabel}>Operator: {driverName}</Text>
          <Text style={styles.remitlabel}>
            Pay Period: {formatDate(startDate)} to {formatDate(endDate)}
          </Text>
          <Text style={styles.remitlabel}>Total Due: {totalCosts}</Text>
          <Text style={styles.remitlabel}>Total Remaining: {totalRemaining}</Text>

        </View>
        <View style={styles.container}>
          <Text style={styles.description}>Dispatch#</Text>
          <Text style={styles.description}>Invoice#</Text>
          <Text style={styles.amount}>Container#</Text>
          <Text style={styles.amount}>Work Date</Text>
          <Text style={styles.amount}>From</Text>
          <Text style={styles.amount}>To</Text>
          <Text style={styles.charges}>Rate</Text>
          <Text style={styles.charges}>Toll</Text>
          <Text style={styles.charges}>Extra</Text>
          <Text style={styles.charges}>FSC</Text>
          <Text style={styles.charges}>FSC%</Text>
          <Text style={styles.amount}>Note</Text>
          <Text style={styles.description}>Cheque</Text>
        </View>
        {PayrollTableRow(drops)}
      </Page>
    </Document>
  );
  const PayrollTableRow = ({ items }) => {
    const rows = drops.map((item) => (
      <View style={styles.row}>
        <Text style={styles.description}>{item.Dispatch}</Text>
        <Text style={styles.description}>{item.invoiceNumber}</Text>
        <Text style={styles.amount}>{item.Container}</Text>
        <Text style={styles.amount}>{item.WorkDate}</Text>
        <Text style={styles.amount}>{item.From}</Text>
        <Text style={styles.amount}>{item.To}</Text>
        <Text style={styles.charges}>{item.Rate}</Text>
        <Text style={styles.charges}>{item.Toll}</Text>
        <Text style={styles.charges}>{item.Extra}</Text>
        <Text style={styles.charges}>{item.Fsc}</Text>
        <Text style={styles.charges}>{item.FscPercent}</Text>
        <Text style={styles.amount}>{item.Note}</Text>
        <Text style={styles.description}>{item.Cheque}</Text>
      </View>
    ));
    return <Fragment>{rows}</Fragment>;
  };
  const MyDocumentAllDrivers = () => (
    <Document rotate={90}>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View>
        <Text style={styles.remitlabel}>{companyName} {driverName}</Text>
          <Text style={styles.remitlabel}>
            Pay Period: {formatDate(startDate)} to {formatDate(endDate)}
          </Text>
          <Text style={styles.remitlabel}>Total Due: {totalCosts}</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.description}>Driver</Text>
          <Text style={styles.description}>TotalRate</Text>
          <Text style={styles.amount}>TotalTolls</Text>
          <Text style={styles.amount}>TotalExtra</Text>
          <Text style={styles.amount}>TotalFsc</Text>
          <Text style={styles.charges}>GrandTotalDue</Text>
        </View>
        {PayrollTableRowAllDrivers(drops)}
      </Page>
    </Document>
  );
  const PayrollTableRowAllDrivers = ({ items }) => {
    const rows = drops.map((item) => (
      <View style={styles.row}>
        <Text style={styles.description}>{item.Driver}</Text>
        <Text style={styles.description}>{item.TotalRate}</Text>
        <Text style={styles.amount}>{item.TotalTolls}</Text>
        <Text style={styles.amount}>{item.TotalExtra}</Text>
        <Text style={styles.amount}>{item.TotalFsc}</Text>
        <Text style={styles.charges}>{item.GrandTotalDue}</Text>
      </View>
    ));
    return <Fragment>{rows}</Fragment>;
  };
  switch (perms) {
    case true:
      return (
        <ThemeProvider theme={theme}>
          <div idleTimer={idleTimer}>
            <CssBaseline />
            <Grid spacing={3} justify="center">
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
              ></link>
              <Grid
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
                xs={12}
                sm={12}
              >
                <Typography component="h1" variant="h5">
                  Payroll Page
                </Typography>
                <Grid
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                  xs={12}
                  sm={12}
                >
                  <FormControl>
                    <Grid item xs={2} sm={2}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          variant="inline"
                          inputFormat="MM/dd/yyyy"
                          label="Start Date"
                          value={startDate}
                          onChange={(e) => {
                            setStartDate(e);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        ></DesktopDatePicker>
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          variant="inline"
                          inputFormat="MM/dd/yyyy"
                          label="End Date"
                          value={endDate}
                          onChange={(e) => {
                            setEndDate(e);
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        ></DesktopDatePicker>
                      </LocalizationProvider>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allDriverCheckBox}
                            onChange={(e) => {
                              toggleCheckBox(e.target.checked);
                            }}
                          />
                        }
                        label="Search By All Drivers"
                      />

                      <FormControl variant="outlined">
                        <Select
                          native
                          value={driverName || "Driver"}
                          onChange={(e) => {
                            setDriverName(e.target.value);
                            setAllDriverCheckBox(false);
                          }}
                        >
                          <option>Driver</option>
                          {drivers.map((driver) => {
                            return (
                              <option value={driver.trim()}>
                                {driver.trim()}
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <Button
                        fullWidth
                        id="searchDriver"
                        variant="contained"
                        color="primary"
                        margin="normal"
                        onClick={getDrops}
                      >
                        Search
                      </Button>
                    </Grid>
                    <Grid item xs={2} sm={2}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="chequenumber"
                        label="Cheque Number"
                        s
                        name="search"
                        margin="normal"
                        autoComplete="off"
                        inputProps={{ readOnly: allDriverCheckBox }}
                        onChange={(e) => {
                          setChequeNumber(e.target.value);
                        }}
                      ></TextField>
                      <Button
                        fullWidth
                        id="applycheques"
                        variant="contained"
                        color="primary"
                        margin="normal"
                        onClick={applyCheques}
                      >
                        Apply
                      </Button>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="totalDue"
                        label="Total Due"
                        name="search"
                        margin="normal"
                        autoComplete="off"
                        value={totalCosts}
                        inputProps={{ readOnly: true }}
                      ></TextField>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="totalRemaining"
                        label="Total Remaining"
                        name="search"
                        margin="normal"
                        autoComplete="off"
                        value={totalRemaining}
                        inputProps={{ readOnly: true }}
                      ></TextField>
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <PDFDownloadLink
                    document={<MyDocument />}
                    filename={"Payroll"}
                  >
                    {({ loading }) =>
                      loading ? (
                        <button>Loading Document...</button>
                      ) : (
                        <button>Download Single</button>
                      )
                    }
                  </PDFDownloadLink>
                  <PDFDownloadLink
                    document={<MyDocumentAllDrivers />}
                    filename={"Payroll"}
                  >
                    {({ loading }) =>
                      loading ? (
                        <button>Loading Document...</button>
                      ) : (
                        <button>Download All Drivers</button>
                      )
                    }
                  </PDFDownloadLink>
                </Grid>
                
                <Grid item xs={12}>
                  <MaterialTable
                    title="Payroll"
                    icons={tableIcons}
                    data={drops}
                    columns={invoiceTableColumns[tableInfo]}
                    options={{
                      paging: false,
                      headerStyle: {
                        backgroundColor: "#DEF3FA",
                        color: "Black",
                        borderRight: "1px solid",
                        width: 100,
                        maxWidth: 100,
                      },
                      cellStyle: {
                        width: 100,
                        maxWidth: 100,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      );
    case false:
      return "you do not have the correct permissions";
    default:
      return "you do not have permissions";
  }
}
