import React, { useState, useEffect, useRef, forwardRef } from "react";
import { CssBaseline, Grid } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useIdleTimer } from "react-idle-timer";
import errorHandle from "./error_handleing.js";
import MaterialTable from "material-table";
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@mui/icons-material";
import Signup from "./signup_page.js";
import { BASE_URL } from "./config"; // Import the BASE_URL variable

window.onerror = function (msg, url, lineNo, columnNo, error) {
  errorHandle(msg, lineNo, columnNo, error);
  alert(
    "msg: ",
    msg,
    "\nlineNo: ",
    lineNo,
    "\ncolumnNo: ",
    columnNo,
    "\nerror: ",
    error
  );
};
export default function Admin() {
  // holds values for all necesary information
  const [users, setUsers] = useState([]);
  const idleTimerRef = useRef(null);
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const [perms, setPerms] = useState(false);
  // axios config
  axios.defaults.withCredentials = true;
  // post request to logout user on idle
  const onIdle = () => {
    try {
      const location = window.location.href.split("/");
      let data = `|||||`;
      axios
        .post(`${BASE_URL}/logout`, {
          location: location[3],
          cache: data,
        })
        .then((response) => {
          window.location = "/";
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: 60 * 15 * 1000,
    onIdle: onIdle,
  });
  const updatePerms = (updatedData) => {
    try {
      axios
        .post(`${BASE_URL}/admin/update`, {
          admin: updatedData.admin,
          email: updatedData.email,
          fname: updatedData.fname,
          dispatch: updatedData.dispatch,
          remittance: updatedData.remittance,
          lname: updatedData.lname,
          none: updatedData.none,
          billing: updatedData.billing,
          usernum: updatedData.usernum,
          active: updatedData.active,
          payroll: updatedData.payroll,
        })
        .then((response) => {
          if (response.data.error) {
          }
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const usersGet = () => {
    try {
      axios
        .post(`${BASE_URL}/admin/getall`)
        .then((response) => {
          let usersarray = [];
          for (let x = 0; x < response.data.searchData.length; x += 11) {
            let usersOBJ = {
              usernum: response.data.searchData[x],
              email: response.data.searchData[x + 1],
              fname: response.data.searchData[x + 2],
              lname: response.data.searchData[x + 3],
              dispatch: response.data.searchData[x + 4],
              billing: response.data.searchData[x + 5],
              none: response.data.searchData[x + 6],
              admin: response.data.searchData[x + 7],
              remittance: response.data.searchData[x + 8],
              active: response.data.searchData[x + 9],
              payroll: response.data.searchData[x + 10],
            };
            if (usersOBJ.dispatch !== "true" && usersOBJ.dispatch !== "1") {
              usersOBJ.dispatch = 0;
            } else if (usersOBJ.dispatch === "true") {
              usersOBJ.dispatch = 1;
            }
            if (usersOBJ.billing !== "true" && usersOBJ.billing !== "1") {
              usersOBJ.billing = 0;
            } else if (usersOBJ.billing === "true") {
              usersOBJ.billing = 1;
            }
            if (usersOBJ.none !== "true" && usersOBJ.none !== "1") {
              usersOBJ.none = 0;
            } else if (usersOBJ.none === "true") {
              usersOBJ.none = 1;
            }
            if (usersOBJ.admin !== "true" && usersOBJ.admin !== "1") {
              usersOBJ.admin = 0;
            } else if (usersOBJ.admin === "true") {
              usersOBJ.admin = 1;
            }
            if (usersOBJ.remittance !== "true" && usersOBJ.remittance !== "1") {
              usersOBJ.remittance = 0;
            } else if (usersOBJ.remittance === "true") {
              usersOBJ.remittance = 1;
            }
            if (usersOBJ.active !== "true" && usersOBJ.active !== "1") {
              usersOBJ.active = 0;
            } else if (usersOBJ.active === "true") {
              usersOBJ.active = 1;
            }
            if (usersOBJ.payroll !== "true" && usersOBJ.payroll !== "1") {
              usersOBJ.payroll = 0;
            } else if (usersOBJ.payroll === "true") {
              usersOBJ.payroll = 1;
            }
            usersarray.push(usersOBJ);
          }
          setUsers(usersarray);
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  // get request to make sure user is infact logged in and load data from last login
  useEffect(() => {
    try {
      axios
        .get(`${BASE_URL}/api/login`)
        .then((response) => {
          if (response.data.loggedIn === true) {
            if (
              response.data.permissions.admin === "true" ||
              response.data.permissions.admin === "1"
            ) {
              setPerms(true);
            }
          } else {
            window.location = "/";
          }
        });
      usersGet();
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, []);
  const theme = createMuiTheme({
    palette: {
      type: "light",
    },
  });
  switch (perms) {
    case true:
      return (
        <ThemeProvider theme={theme}>
          <div idleTimer={idleTimer}>
            <CssBaseline />
            <link
              rel="stylesheet"
              href="https://fonts.googleapis.com/icon?family=Material+Icons"
            ></link>
            <Grid container spacing={1}>
              <Grid container item xs={12} sm={6}>
                <MaterialTable
                  icons={tableIcons}
                  title="Users"
                  data={users}
                  columns={[
                    { field: "email", title: "Email", editable: "never" },
                    { field: "fname", title: "First", editable: "never" },
                    { field: "lname", title: "Last", editable: "never" },
                    { field: "none", title: "None", type: "boolean" },
                    { field: "dispatch", title: "Dispatch", type: "boolean" },
                    { field: "billing", title: "Billing", type: "boolean" },
                    {
                      field: "remittance",
                      title: "Remittance",
                      type: "boolean",
                    },
                    { field: "admin", title: "Admin", type: "boolean" },
                    { field: "payroll", title: "Payroll", type: "boolean" },
                    { field: "active", title: "Active", type: "boolean" },
                  ]}
                  editable={{
                    onRowUpdate: (newRow, oldRow) =>
                      new Promise((resolve, reject) => {
                        console.dir(newRow);
                        console.dir(oldRow);

                        // if(newRow.active !== "true" && newRow.active !== 1){
                        //   newRow.dispatch = false;
                        //   newRow.billing = false;
                        //   newRow.remittance = false;
                        //   newRow.admin = false;
                        //   newRow.none = false;
                        //   newRow.payroll = false;
                        // }
                        // if(newRow.none === true || newRow.none === 1){
                        //   newRow.dispatch = false;
                        //   newRow.billing = false;
                        //   newRow.remittance = false;
                        //   newRow.admin = false;
                        //   newRow.payroll = false;
                        // }
                        updatePerms(newRow);
                        const updatedUsers = [...users];
                        updatedUsers[oldRow.tableData.id] = newRow;
                        setUsers(updatedUsers);
                        setTimeout(() => resolve(), 500);
                      }),
                  }}
                  options={{
                    paging: false,
                    cellStyle: { borderRight: "1px solid " },
                    headerStyle: { borderRight: "1px solid " },
                    maxBodyHeight: 700,
                  }}
                />
              </Grid>
              <Grid container item xs={12} sm={6}>
                <Signup />
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      );
    case false:
      return "you do not have the correct permissions";
    default:
      return "you do not have permissions";
  }
}
