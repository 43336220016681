import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, Typography } from "@material-ui/core";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import axios from "axios";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData.js";
import "./css/navbar.css";
import { IconContext } from "react-icons";
import Customer from "./customer_page.js";
import Status from "./status_page.js";
import Shipper from "./shipper_page.js";
import Location from "./locations_page.js";
import PortLocations from "./port_locations_page.js";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import { confirmAlert } from "react-confirm-alert";
import { BASE_URL, companyName } from "./config"; // Import the BASE_URL variable

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Nav() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const showSidebar = () => setSidebar(!sidebar);
  // axios config
  axios.defaults.withCredentials = true;
  // post request for logout
  const logout = () => {
    const location = window.location.href.split("/");
    axios
      .post(`${BASE_URL}/logout`, {
        location: location[3],
        cache: "",
      })
      .then((response) => {
        window.location.reload();
      });
  };
  const toggleModal = () => {
    setModal(!modal);
  };
  useEffect(() => {
    // get request to make sure user is infact logged in
    axios.get(`${BASE_URL}/api/login`).then((response) => {
      if (response.data.loggedIn === true) {
        setLoggedIn(true);
      }
    });
  }, []);
  const selectmodalcontent = () => {
    if (modalContent === "Customer") {
      return <Customer />;
    }
    if (modalContent === "Drivers") {
      return <Status />;
    }
    if (modalContent === "Shippers") {
      return <Shipper />;
    }
    if (modalContent === "Locations") {
      return <Location />;
    }
    if (modalContent === "Port Locations") {
      return <PortLocations />;
    }
  };
  const dispatchOptions = {
    title: "Leaving Page",
    message:
      "Any unsaved changes will be lost. Are you sure you would like to leave this page?",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          window.location = "/dispatch";
        },
      },
      {
        label: "No",
        onClick: () => {},
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };
  const boardOptions = {
    title: "Leaving Page",
    message:
      "Any unsaved changes will be lost. Are you sure you would like to leave this page?",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          window.location = "/board";
        },
      },
      {
        label: "No",
        onClick: () => {},
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };
  const homeOptions = {
    title: "Leaving Page",
    message:
      "Any unsaved changes will be lost. Are you sure you would like to leave this page?",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          window.location = "/home";
        },
      },
      {
        label: "No",
        onClick: () => {},
      },
    ],
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name",
  };
  const pageName = () => {
    let pagelocation = window.location.href.split("/");
    return pagelocation[3];
  };
  switch (loggedIn) {
    case true:
      return (
        <IconContext.Provider value={{ color: "#fff" }}>
          <div className="navbar">
            <Link to="#" className="menu-bars">
              <FaIcons.FaBars onClick={showSidebar} />
            </Link>
            <Typography variant="h6" align="right">
              {companyName}
            </Typography>
            <Typography variant="h6" align="center">
              :{pageName()}
            </Typography>
            <Button
              width="50%"
              variant="contained"
              color="primary"
              align="left"
              onClick={() => {
                let pagelocation = window.location.href.split("/");
                if (pagelocation[3] === "dispatch") {
                  confirmAlert(homeOptions);
                } else {
                  window.location = "/home";
                }
              }}
            >
              <AiIcons.AiFillHome />
              <span className="navspan">Home</span>
            </Button>
          </div>
          <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
            <ul className="nav-menu-items" onClick={showSidebar}>
              <li className="navbar-toggle">
                <Link to="#" className="menu-bars">
                  <AiIcons.AiOutlineClose />
                </Link>
              </li>
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Button
                      width="50%"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        let pagelocation = window.location.href.split("/");
                        if (item.title === "Board") {
                          if (pagelocation[3] === "dispatch") {
                            confirmAlert(boardOptions);
                          } else {
                            window.location = "/board";
                          }
                        } else if (item.title === "Dispatch") {
                          if (pagelocation[3] === "dispatch") {
                            confirmAlert(dispatchOptions);
                          } else {
                            window.location = "/dispatch";
                          }
                        } else if (item.title === "Home") {
                          if (pagelocation[3] === "dispatch") {
                            confirmAlert(homeOptions);
                          } else {
                            window.location = "/home";
                          }
                        } else if (item.title === "Remittance") {
                          window.location = "/invoice";
                        } else if (item.title === "Billing") {
                          window.location = "/billing";
                        } else if (item.title === "Payroll") {
                          window.location = "/payroll";
                        } else {
                          setModalContent(item.title);
                          toggleModal();
                        }
                      }}
                    >
                      {item.icon}
                      <span className="navspan">{item.title}</span>
                    </Button>
                  </li>
                );
              })}
              <li className="nav-text">
                <Link to="/">
                  <Button
                    type="submit"
                    style={{ maxWidth: "150px", minWidth: "150px" }}
                    color="inherit"
                    onClick={logout}
                  >
                    Logout
                  </Button>
                </Link>
              </li>
            </ul>
          </nav>
          <Dialog
            fullScreen
            open={modal}
            onClose={toggleModal}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: "relative" }}>
              <Toolbar className="dialogboard">
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={toggleModal}
                  aria-label="close"
                >
                  <CloseIcon align="right" />
                </IconButton>
                <Typography
                  align="right"
                  sx={{ ml: 2, flex: 1 }}
                  variant="h6"
                  component="div"
                >
                  Dispatch
                </Typography>
              </Toolbar>
            </AppBar>
            <DialogContent className="dialogbg">
              {selectmodalcontent()}
            </DialogContent>
          </Dialog>
        </IconContext.Provider>
      );
    default:
      return (
        <div className="navbar">
          <div className="nav-text">
            <Link to="/">
              <Button color="inherit">Login</Button>
            </Link>
          </div>
          <Typography variant="h6" align="right">
            {companyName}
          </Typography>
        </div>
      );
  }
}
