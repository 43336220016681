import axios from 'axios';
import { BASE_URL } from "./config"; // Import the BASE_URL variable

export default function errorHandle(msg, url, lineNo, columnNo, error) {
    let fullError = "msg: "+ msg + "\nlineNo: " + lineNo + "\ncolumnNo: " + columnNo + "\nerror: " + error;
    try{
        axios.post("/errorHandle", {
            fullError: fullError
        }).then((response) => {
            alert("from alert:\n", fullError)
            console.log("from alert:\n", fullError)
        }, (error) => {
          console.log(error);
        });
      }catch(err){
        console.log(err)
        alert(err)
      }
    return false;
}

export function dbErrorHandle(fullError) {
  try{
      axios.post("/errorHandle", {
          fullError: fullError
      }).then((response) => {
          alert("from alert:\n", fullError)
          console.log("from alert:\n", fullError)
      }, (error) => {
        console.log(error);
      });
    }catch(err){
      console.log(err)
      alert(err)
    }
  return false;
}