import React, { useState } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
} from "@material-ui/core";
import axios from "axios";
import { BASE_URL } from "./config"; // Import the BASE_URL variable

export default function Signup() {
  const [user_fname, setUserFname] = useState("");
  const [user_lname, setUserLname] = useState("");
  const [user_email, setUserEmail] = useState("");
  const [user_password, setUserPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const signup = () => {
    const isNumberRegex = /\d/;
    const specialCharacterRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (user_email !== "") {
      if (
        isNumberRegex.test(user_password) &&
        specialCharacterRegex.test(user_password) &&
        user_password.length > 8
      ) {
        if (user_password === confirm_password) {
          axios
            .post(`${BASE_URL}/signup`, {
              user_fname: user_fname,
              user_lname: user_lname,
              user_email: user_email,
              user_password: user_password,
              confirm_password: confirm_password,
            })
            .then((response) => {
              alert(response.data.check);
              if (response.data.check === "user created") {
                alert(response.data.check);
                window.location.reload();
              }
            });
        } else {
          alert("passwords did not match");
        }
      } else {
        alert(
          "must have number, special character and be over 8 characters long"
        );
      }
    } else {
      alert("please enter a Username");
    }
  };
  function handleSubmit(event) {
    event.preventDefault();
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="user_fname"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                onChange={(e) => {
                  setUserFname(e.target.value);
                }}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="user_lname"
                autoComplete="lname"
                onChange={(e) => {
                  setUserLname(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Username"
                name="user_email"
                autoComplete="email"
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="user_password"
                label="user_password"
                type="password"
                id="password"
                autoComplete="user-password"
                onChange={(e) => {
                  setUserPassword(e.target.value);
                }}
              />
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirm_password"
                label="Confirm Password"
                type="password"
                id="confirm_password"
                autoComplete="confirm-password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={signup}
          >
            Sign Up
          </Button>
        </form>
      </div>
    </Container>
  );
}
