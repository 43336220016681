import React, { useState, useEffect, useRef, forwardRef } from "react";
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { useIdleTimer } from "react-idle-timer";
import errorHandle from "./error_handleing.js";
import MaterialTable from "material-table";
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
  TrendingUpRounded,
} from "@mui/icons-material";
import { BASE_URL } from "./config"; // Import the BASE_URL variable

window.onerror = function (msg, url, lineNo, columnNo, error) {
  errorHandle(msg, lineNo, columnNo, error);
  alert(
    "msg: ",
    msg,
    "\nlineNo: ",
    lineNo,
    "\ncolumnNo: ",
    columnNo,
    "\nerror: ",
    error
  );
};

export default function Shipper() {
  const [perms, setPerms] = useState(false);
  const [readonly, setreadonly] = useState(false);
  const [shippers, setShippers] = useState([]);
  const [shipper_code, setShipperCode] = useState("");
  const [billing_name, setBillingName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const idleTimerRef = useRef(null);
  let today = new Date();
  today =
    String(today.getMonth() + 1).padStart(2, "0") +
    "/" +
    String(today.getDate()).padStart(2, "0") +
    "/" +
    today.getFullYear();
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  axios.defaults.withCredentials = true;
  const onIdle = () => {
    try {
      const location = window.location.href.split("/");
      let data = `${shipper_code}|${billing_name}|${street}|${city}|${zipcode}|${state}`;
      axios
        .post(`${BASE_URL}/logout`, {
          location: location[3],
          cache: data,
        })
        .then((response) => {
          window.location = "/";
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const shipperInsert = () => {
    try {
      if (
        !(
          shipper_code === "" ||
          billing_name === "" ||
          street === "" ||
          city === "" ||
          zipcode === "" ||
          state === ""
        )
      ) {
        axios
          .post(`${BASE_URL}/shipper/upsert`, {
            shipper_code: shipper_code,
            billing_name: billing_name,
            street: street,
            city: city,
            zipcode: zipcode,
            state: state,
            date: today,
            type: "insert",
          })
          .then((response) => {
            if (response.data.check === "Shipper Added") {
              let shipperUpdateOBJ = {
                code: shipper_code,
                name: billing_name,
                street: street,
                city: city,
                state: state,
                zipcode: zipcode,
              };
              let newShippers = [...shippers];
              newShippers.push(shipperUpdateOBJ);
              setShippers(newShippers);
              setShipperCode("");
              setBillingName("");
              setStreet("");
              setCity("");
              setZipcode("");
              setState("");
              alert(response.data.check);
            } else {
              alert(response.data.check);
            }
          });
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const shipperUpdate = () => {
    try {
      if (
        !(
          shipper_code === "" ||
          billing_name === "" ||
          street === "" ||
          city === "" ||
          zipcode === "" ||
          state === ""
        )
      ) {
        axios
          .post(`${BASE_URL}/shipper/upsert`, {
            shipper_code: shipper_code,
            billing_name: billing_name,
            street: street,
            city: city,
            zipcode: zipcode,
            state: state,
            date: today,
            type: "update",
          })
          .then((response) => {
            alert(response.data.check);
            console.dir(response.data.check);
            shipperGet();
            setreadonly(false);
            setShipperCode("");
            setBillingName("");
            setStreet("");
            setCity("");
            setZipcode("");
            setState("");
          });
      }
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const newShipper = () => {
    try {
      shipperGet();
      setreadonly(false);
      setShipperCode("");
      setBillingName("");
      setStreet("");
      setCity("");
      setZipcode("");
      setState("");
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const shipperGet = () => {
    try {
      axios
        .post(`${BASE_URL}/shipper/getall`)
        .then((response) => {
          let shipperarray = [];
          console.log(response.data.searchData);
          console.dir(response.data.searchData);

          for (let x = 0; x < response.data.searchData.length; x += 13) {
            let shipperOBJ = {
              code: response.data.searchData[x + 1],
              name: response.data.searchData[x + 2],
              street: response.data.searchData[x + 3],
              city: response.data.searchData[x + 4],
              zip: response.data.searchData[x + 5],
              state: response.data.searchData[x + 6],
            };
            shipperarray.push(shipperOBJ);
            console.dir(shipperOBJ);
          }
          setShippers(shipperarray);
          console.dir(shipperarray);
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const shipperSet = (data) => {
    setShipperCode(data.code);
    setBillingName(data.name);
    setStreet(data.street);
    setCity(data.city);
    setZipcode(data.zip);
    setState(data.state);
  };
  useEffect(() => {
    try {
      axios
        .get(`${BASE_URL}/api/login`)
        .then((response) => {
          if (response.data.loggedIn === true) {
            if (
              response.data.permissions.admin === "true" ||
              response.data.permissions.admin === "1"
            ) {
              setPerms(true);
            }
            if (
              response.data.permissions.dispatch === "true" ||
              response.data.permissions.dispatch === "1"
            ) {
              setPerms(true);
            }
          } else {
            window.location = "/";
          }
        });
      shipperGet();
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, []);
  function handleSubmit(event) {
    event.preventDefault();
  }
  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: 60 * 15 * 1000,
    onIdle: onIdle,
  });
  function handleClick(selectedRow) {
    setreadonly(true);
    shipperSet(shippers[selectedRow.tableData.id]);
  }
  switch (perms) {
    case true:
      return (
        <Grid container spacing={3} style={{ marginTop: 30 }} justify="center">
          <div idleTimer={idleTimer}>
            <CssBaseline />
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
                <MaterialTable
                  icons={tableIcons}
                  title="Shippers"
                  data={shippers}
                  columns={[
                    { field: "code", title: "code" },
                    { field: "name", title: "name" },
                    { field: "street", title: "street" },
                    { field: "city", title: "city" },
                    { field: "state", title: "state" },
                  ]}
                  options={{
                    paging: false,
                    cellStyle: { borderRight: "1px solid " },
                    headerStyle: { borderRight: "1px solid " },
                    maxBodyHeight: 700,
                  }}
                  onRowClick={(evt, selectedRow) => handleClick(selectedRow)}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography component="h1" variant="h5">
                  shipper Page
                </Typography>
                <form onSubmit={handleSubmit}>
                  <TextField
                    variant="outlined"
                    value={shipper_code || ""}
                    required
                    fullWidth
                    id="shipper_code"
                    label="shipper_code"
                    name="shipper_code"
                    style={{ marginTop: 10 }}
                    inputProps={{ maxLength: 3, readOnly: readonly }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setShipperCode("");
                      } else {
                        setShipperCode(e.target.value);
                      }
                    }}
                    autoFocus
                    onKeyPress={(e) => {
                      if (e.which === 32) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <TextField
                    variant="outlined"
                    value={billing_name || ""}
                    required
                    fullWidth
                    id="billing_name"
                    label="billing_name"
                    name="billing_name"
                    style={{ marginTop: 10 }}
                    inputProps={{ readOnly: readonly }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setBillingName("");
                      } else {
                        setBillingName(e.target.value);
                      }
                    }}
                  />
                  <TextField
                    variant="outlined"
                    value={street || ""}
                    required
                    fullWidth
                    id="street"
                    label="street"
                    name="street"
                    style={{ marginTop: 10 }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setStreet("");
                      } else {
                        setStreet(e.target.value);
                      }
                    }}
                  />
                  <TextField
                    variant="outlined"
                    value={city || ""}
                    required
                    fullWidth
                    id="city"
                    label="city"
                    name="city"
                    style={{ marginTop: 10 }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setCity("");
                      } else {
                        setCity(e.target.value);
                      }
                    }}
                  />
                  <TextField
                    variant="outlined"
                    value={zipcode || ""}
                    required
                    fullWidth
                    id="zipcode"
                    label="zipcode"
                    name="zipcode"
                    style={{ marginTop: 10 }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setZipcode("");
                      } else {
                        setZipcode(e.target.value);
                      }
                    }}
                  />
                  <TextField
                    variant="outlined"
                    value={state || ""}
                    required
                    fullWidth
                    id="state"
                    label="state"
                    name="state"
                    style={{ marginTop: 10 }}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setState("");
                      } else {
                        setState(e.target.value);
                      }
                    }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={shipperUpdate}
                  >
                    Update Shipper
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={shipperInsert}
                  >
                    Create Shipper
                  </Button>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={newShipper}
                  >
                    New Shipper
                  </Button>
                </form>
              </Grid>
            </Grid>
          </div>
        </Grid>
      );
    case false:
      return "you do not have the correct permissions";
    default:
      return "you do not have permissions";
  }
}
